:root {
  /* The card width; must match CARD_WIDTH_PX in Card.jsx */
  --card-width: 184px; /* 11.5rem */
  --card-height: 31px; /* 1.9em */

  --blue: #06c;
  --blue-hover: #09f;
  --green: #28a745;
  --green-light: #15ea1599;
  --green-dark: green;
  --red-pale: #fac5cd;
  --red-pale-dark: #cd5c5c;
  --gray-background: #ddd;

  /* Modified from basis of Mana Font colors */
  /* https://github.com/andrewgioia/mana */
  --mtg-white: #f0f2c0;
  --mtg-white-dark: #dfd7a1;
  --mtg-blue: #b5cde3;
  --mtg-blue-dark: #5790c4;
  --mtg-black: #aca29a;
  --mtg-black-dark: #555;
  --mtg-red: #db8664;
  --mtg-red-dark: #c6553e;
  --mtg-green: #93b483;
  --mtg-green-dark: #548454;
  --mtg-colorless: #beb9b2;
  --mtg-colorless-dark: #888;
  --mtg-gold: #D4AF37; /* Metallic Gold */

  /* Custom rarity colors */
  --rarity-mythic: #ff6000;
  --rarity-rare: gold;
  --rarity-uncommon: #97b5db;
  --rarity-common: white;
}

/* Custom colorless color indicator to extend Mana Font */
.ms-ci-c {
  background-color: var(--mtg-colorless);
}

/*********************************************************/
/* Override default Mana Font style positions            */
.ms::before {
  position: relative;
  top: -0.02em;
}

.ms-w::before {
}

.ms-u::before {
  position: relative;
  left: 0.01em;
}

.ms-r::before {
  position: relative;
  left: 0.02em;
}

.ms-g::before {
  position: relative;
  left: 0.01em;
}

/* END override default Mana Font style positions        */
/*********************************************************/

body, html, #root {
  height: 100%;
  width: 100%;
  -webkit-text-size-adjust: 100%;

  font-family: "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
}

#root {
  display: flex;
  margin: 0;
  padding: 0;
}

.sidebar {
  width: 13rem;
  min-width: 13rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.support {
  margin: 0 auto 0.2em;
  text-align: center;
}

.contact {
  text-align: center;
  white-space: nowrap;
  font-size: 0.9rem;
  min-width: 11.8em;
  margin: 0.2em;
}

.contact a {
  cursor: pointer;
  outline: none;
  margin: 0 .1em;
}
.contact a.icon {
  vertical-align: middle;
  font-size: 0.9rem;
}

.main {
  margin-top: 0.5em;
  text-align: center;
}

a.title {
  display: block;
  font-family: 'Beleren Bold', Georgia, 'Times New Roman', Times, serif;
  font-size: 1.35rem;
  color: var(--blue);
  white-space: nowrap;
}
a.title:hover {
  text-decoration: none;
}

a,
button.link-button {
  color: var(--blue);
  text-decoration: none;
}
button.link-button {
  background-color: transparent !important;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}
a:hover {
  color: var(--blue-hover);
  text-decoration: underline;
  cursor: pointer;
}
/* Only apply this hover style to the button if the browser supports 
   proper/true/real/non-emulated hovering (i.e., not touch)
   https://stackoverflow.com/a/28058919/186818 */
@media (hover: hover) {
  button.link-button:hover {
    color: var(--blue-hover);
    text-decoration: underline;
  }
}

/* Abbreviation styling                                   */
/* See https://www.samanthaming.com/tidbits/98-html-abbr/ */
abbr {
  text-decoration: underline dotted;
}
abbr:hover {
  cursor: help;
}

/* Hide the file input widget */
.inputfile {
	opacity: 0;
  position: absolute;
  pointer-events: none;
	width: 0.1px;
	height: 0.1px;
	overflow: hidden;
	z-index: -1;
}

button,
.inputfile + label.button,
.button-link {
  display: inline-block;
  white-space: nowrap;
  background-color: white;
  color: #333;
  
  font-family: inherit;
  font-size: inherit;
  /* This gives a little extra padding to better-center the text */
  line-height: 1.2;
  /* Add a _little_ extra padding below the text within buttons;
   * Particularly to fix noticeable offset with Windows (?) font */
  padding: .1em .4em .14em;
  margin: 0.3em 0.2em;

  border-radius:.25rem;
  border: 1px solid #333;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Only apply this hover style to the button if the browser supports 
   proper/true/real/non-emulated hovering (i.e., not touch)
   https://stackoverflow.com/a/28058919/186818 */
@media (hover: hover) {
  button:hover:enabled,
  .inputfile + label:hover,
  a.button-link:hover {
    cursor: pointer;
    background-color: var(--blue-hover);
  }
  button:active:hover:enabled,
  .inputfile:enabled + label:hover:active,
  a.button-link:active:hover {
    background-color: var(--blue);
  }
}

button:disabled,
.inputfile:disabled + label {
  opacity: 0.5;
  color: #111;
  background-color: #999;
  cursor: default;
}

.main button,
.main .inputfile + label.button,
.modal button {
  font-size: 1.1rem;
  padding: .2em .4em;
}

label:enabled {
  display: inline-block;
  cursor: pointer;
}

/* Load Button styling (spinner while loading) */
button.loading .fa-spinner,
.inputfile + label.loading {
  /* set width when spinner is visible */
  width: 7.4rem;
}

/* Publish Button styling */
button.publish {
  width: 7rem;
  color: white;
  background-color: var(--green);
  border-color: var(--green);
}
/* Toggle between Publish text and spinner */
button.publish.publishing .text {
  display: none;
}
button.publish:not(.publishing) .spinner {
  display: none;
}
button.publish:disabled {
  opacity: 0.5;
  background-color: var(--green-light);
  color: var(--green-dark);
}

button.export-seventeenlands-tierlist {
  background-color: #343a40;
  color: white;
}

button.export-seventeenlands-tierlist:hover {
  background-color: #292929;
  color: #ddd;
}

button.export-seventeenlands-tierlist:disabled {
  opacity: 0.5;
  background-color: #343a40;
  color: white;
}

/* Only apply this hover style to the button if the browser supports 
   proper/true/real/non-emulated hovering (i.e., not touch)
   https://stackoverflow.com/a/28058919/186818 */
@media (hover: hover) {
  button:hover:enabled.publish {
    /* font-weight: 700; */
    /* use text-shadow instead of font-weight to prevent resizing the button */
    text-shadow: -0.06ex 0 white, 0.06ex 0 white;
    background-color: var(--green);
    opacity: 0.85;
  }
  button:active:hover:enabled.publish {
    background-color: var(--green-dark);
    border-color: var(--green-dark);
  }
}

button.revert {
}

.recent-pools {
  font-size: 0.9rem;
  font-weight: 600;
  margin-top: 0.5rem;
  cursor: default;
}

.controlpanel {
  margin: 0.3em 0;
  flex: 1;
  text-align: center;
}

/*********************************************************/
/* START of ControlPanel section styling                 */
.filters, 
.tech,
.admin {
  background-color: var(--gray-background);
  padding-bottom: 1em;
}
.admin {
  text-align: center;
}

.filter-header,
.tech-header,
.admin-header {
  background: #000;
  color: #ccc;
  padding: 0.3em 0.4em 0.1em;
  margin-bottom: 0.5em;
}

.filter-title,
.tech-title,
.admin-title {
  font-family: 'Beleren Bold', Georgia, 'Times New Roman', Times, serif;
  font-size: 1.3em;
  white-space: nowrap;
}

.filter-body,
.tech-body {
  padding: 0 0.2em;
}

hr {
  height: 1px;
  border: none;
  background: #333; 
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}

.filter-colors,
.filter-types { 
    margin: 0.2em 0;
}

.filter-text {
  margin: 0.2em 0;
}

.filter-secondary-switch {
}

/*********************************************************/
/* Color/Land/Types filter button and image styling      */
.filter-colors button,
.filter-types button,
.filter-rarity button {
  font-family: sans-serif;
  font-size: 1.2em;
  margin: 0 0.04em;

  width: 1.5em;
  height: 1.5em;
  padding: 0;

  border: 0.15em solid var(--gray-background);
  border-radius: 1em;
  color: white;
  background-color: black;
}

.filter-controls {
  padding-top: 0.4em;
}
button.filter-clear {
  font-size: 0.9rem;
}
/* Only apply this hover style to the button if the browser supports 
   proper/true/real/non-emulated hovering (i.e., not touch)
   https://stackoverflow.com/a/28058919/186818 */
@media (hover: hover) {
  button.filter-clear:enabled:hover {
    background-color: var(--red-pale);
  }
  button.filter-clear:active:hover:enabled {
    background-color: var(--red-pale-dark);
  }
}

.filter-types button .ms {
  /* adjust the non-color icon sizes */
  font-size: 0.85em;
}

.filter-colors button .ms,
.filter-types button .ms,
.filter-rarity button .ms {
  /* adjust the position of the Mana Font icon within the buttons */
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-types button .ms-e {
  display: inline-block;
  /* adjust the energy icon size and offset position */
  font-size: 1.04em;
  margin-top: -0.14em;
}

/* adjust the multicolor indicator icon for the strict color filter button */
.filter-colors-strict button .ms-ci {
  margin-left: 0.4em;
  font-size: 1.1em;
  vertical-align: text-bottom;
}

/* special styling for the lesson filter button (text, no icon) */
button.filter-bargain,
button.filter-bargainable,
button.filter-energy,
button.filter-lesson,
button.filter-outlaws,
/* and special styling for the strict color filter button (text AND icon) */
button.colors-strict,
/* similar rectangular styling for the rarity filter buttons */
.filter-rarity button {
  width: auto;
  height: auto;
  font-size: 1rem;
  font-family: inherit;
  
  /* try to visually match the default button styling */
  border-radius: 0.25rem;
  border: 0.1em solid black;
  padding: 0.1em .5em;
  margin: 0.3em 0.2em;
}
/* thicker border for rarity buttons to match the color/type buttons */
.filter-rarity button {
  border-width: 0.15em;
}

/* all color buttons when selected or on mouse over */
.filter-colors button:hover:enabled,
.filter-colors button.filter-selected {
  color: #111;
}
/* specific colors when selected or on mouse over */
button.filter-white:hover:enabled,
button.filter-white.filter-selected {
  background-color: var(--mtg-white);
  border-color: var(--mtg-white-dark);
}
button.filter-blue:hover:enabled,
button.filter-blue.filter-selected {
  background-color: var(--mtg-blue);
  border-color: var(--mtg-blue-dark);
}
button.filter-black:hover:enabled,
button.filter-black.filter-selected {
  background-color: var(--mtg-black);
  border-color: var(--mtg-black-dark);
}
button.filter-red:hover:enabled,
button.filter-red.filter-selected {
  background-color: var(--mtg-red);
  border-color: var(--mtg-red-dark);
}
button.filter-green:hover:enabled,
button.filter-green.filter-selected {
  background-color: var(--mtg-green);
  border-color: var(--mtg-green-dark);
}
button.filter-colorless:hover:enabled,
button.filter-colorless.filter-selected {
  background-color: var(--mtg-colorless);
  border-color: var(--mtg-colorless-dark);
}

/* style button for card types (not colors) */
.filter-types button:hover:enabled,
.filter-types button.filter-selected,
/* style button for strict color filter */
.filter-colors-strict button:hover:enabled,
.filter-colors-strict button.filter-selected {
  color: black;
  border-color: purple;
  background-color: plum;
}

/* style the rarity filter buttons */
.filter-rarity button { 
  width: 2rem;
  margin: 0.2em;
}
.filter-rarity button:hover:enabled,
.filter-rarity button.filter-selected {
  border-color: purple;
}

/* Mythic rarity */
.rarity-mythic {
  color: var(--rarity-mythic);
}
/* Mythic rarity - selected/hover */
button.filter-mythic.filter-selected,
button.filter-mythic:hover:enabled {
  border-color: var(--rarity-mythic);
  background-color: #ffe0cc;
}

/* Rare rarity */
.rarity-rare,
/* hover over selected rare icons (show default color) */
button.filter-rare.filter-selected:hover:enabled .rarity-rare {
  color: var(--rarity-rare);
}
/* Rare rarity - selected/hover */
button.filter-rare.filter-selected,
button.filter-rare:hover:enabled {
  border-color: #c4951d;
  background-color: #f8edd2;
}
/* Rare rarity - selected/hover icon */
button.filter-rare.filter-selected .rarity-rare,
button.filter-rare:hover:enabled .rarity-rare {
  color: #c4951d;
}

/* Uncommon rarity */
.rarity-uncommon,
/* hover over selected uncommon icons (show default color) */
button.filter-uncommon.filter-selected:hover:enabled .rarity-uncommon {
  color: var(--rarity-uncommon);
}
/* Uncommon rarity - selected/hover */
button.filter-uncommon.filter-selected,
button.filter-uncommon:hover:enabled {
  border-color: #7488a2;
  background-color: #e0e7f2;
}
/* Uncommon rarity - selected/hover icon */
button.filter-uncommon.filter-selected .rarity-uncommon,
button.filter-uncommon:hover:enabled .rarity-uncommon {
  color: #7488a2;
}
button.filter-uncommon.filter-selected:hover:enabled .rarity-uncommon {
  color: var(--rarity-uncommon);
}

/* Common rarity */
.rarity-common,
/* hover over selected common icons (show default color) */
button.filter-common.filter-selected:hover:enabled .rarity-common {
  color: var(--rarity-common);
}
/* Common rarity - selected/hover */
button.filter-common.filter-selected,
button.filter-common:hover:enabled {
  border-color: black;
  background-color: #fbfbfb;
  color: black;
}
button.filter-common.filter-selected:hover:enabled {
  border-color: white;
}
/* Common rarity - selected/hover icon */
button.filter-common.filter-selected .rarity-common,
button.filter-common:hover:enabled .rarity-common {
  color: black;
}

/* hover over a selected icon */
button.filter-selected:hover:enabled {
  color: #999;
  background-color: #666;
}
/* hover over a selected rarity icon */
button.filter-rare.filter-selected:active:enabled .rarity-rare {
  color: goldenrod;
}
button.filter-uncommon.filter-selected:hover:active:enabled .rarity-uncommon {
  color: #7a7a7a;
}
button.filter-common.filter-selected:hover:active:enabled .rarity-common {
  color: black;
}

/* during mouse-down of an icon (selected or not) */
.filter-types button:active:hover:enabled,
.filter-colors button:active:hover:enabled,
.filter-colors-strict button:active:hover:enabled,
.filter-rarity button:active:hover:enabled {
  color: black;
  background-color: #ccc;
}
/* END Color/Land/Types buttons and image styling        */
/*********************************************************/

.tech select {
  font-size: 1em;
}

.ratings {
  text-align: left;
  margin: 0.6em 0 0.6em 0.7em;
}

.ratings-show-switch {
}

.ratings .ratings-darkestmage,
.ratings .ratings-lordsoflimited,
.ratings .ratings-chord_o_calls {
  padding: 0.5em 0;
  margin-left: 0.46em;
  font-size: 0.9em;
}

.ratings-set-code {
  font-size: 0.9em;
  color: #444;
  display: inline-block;
}

.expan.ratings-sources {
  display: inline-block;
}

.expan.ratings-sources .ratings-set-code {
  padding: 0.1em 0.2em;
}

.seventeenlands-gihwr{
  margin: 0.6em 0;
}

.seventeenlands-gihwr {
  margin-bottom: 0.9em;
}

.recent-pools a,
.recent-pools span.current {
  text-decoration: none;
  font-weight: 500;
  letter-spacing: -0.05em;
}
.recent-pools a:hover span {
  text-decoration: underline;
}

.set {
  font-size: 0.9em;
  font-weight: normal;
}

/* Link and icon styling for recently viewed links */
.recent-pools span.current {
  font-weight: normal;
  color: #666;
}
.recent-pools span.current:hover {
  color: #999;
}

.recent-pool-id .tierlist {
  color: black;
}

.recent-pool-id {
  padding: 0.2em 0;
  font-family: monospace, monospace;
  line-height: 1.1rem;
}
.recent-pool-id .id-small {
  font-size: 0.9em;
}
.recent-pool-id .ss {
  font-size: 1.2em;
  color: black;
}
.recent-pool-id .ms-cost {
  font-size: 0.9em;
}
.recent-pool-id .ss,
.recent-pool-id .ms-cost {
  vertical-align: text-bottom;
  text-decoration: none;
}
.recent-pool-id .color-splash {
  font-size: 1.5em;
  /* center-align splash color with ms-cost icons */
  vertical-align: -0.15em;
}
.recent-pool-id a:hover .color-splash {
  text-decoration: none;
}
.recent-pool-id .color-splash-w {
  color: var(--mtg-white-dark);
}
.recent-pool-id .color-splash-u {
  color: var(--mtg-blue-dark);
}
.recent-pool-id .color-splash-b {
  color: var(--mtg-black-dark);
}
.recent-pool-id .color-splash-r {
  color: var(--mtg-red-dark);
}
.recent-pool-id .color-splash-g {
  color: var(--mtg-green-dark);
}

/* 17Lands and Card Ratings sidebar widgets */
.ratings .message,
.seventeenlands-gihwr .message {
  text-align: center;
  font-size: 0.8rem;
  padding: 0 0.5em;
  margin-top: 0.2em;
}

.message.tooltip {
  cursor: help;
}

.pulse {
  animation: pulser 2s ease-in-out infinite;
}

@keyframes pulser {
  50% {
    opacity: 0;
  }
}

.ratings .error,
.seventeenlands-gihwr .error {
  color: darkred;
}

.seventeenlands-gihwr .error.embargo {
  background-color: #e3c0c4;
  margin: 0.5em;
  border: 1px solid darkred;
  border-radius: 0.2em;
  padding: 0.2em;
}

.seventeenlands-gihwr .warning {
  color: #333;
}

.seventeenlands-gihwr-format {
  margin: 0.3em 0;
}

.seventeenlands-gihwr abbr.gihwr {
  letter-spacing: -0.04em;
}

/* END of ControlPanel section styling                   */
/*********************************************************/


/* Style the div that contains the pool panes */
div.cardpool-panes {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/***************************************************************************************/
/* Style the split pane (.SplitPane .cardpool-panes) from the react-split-pane library */

/* Undo default styling of .SplitPane container from the react-split-pane library */
.SplitPane {
  position: initial !important;
  min-height: initial !important;

  /* These styles combine with changes to make the scrollbars appear based
     on the width of the .pool-header content. */
  overflow: visible !important;
  width: auto !important;
}

/* Style the pane containing the sideboard and deck pools from the react-split-pane library */
.cardpool-panes .Pane {
  position: static !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
/***************************************************************************************/

/* Top Panel (Sideboard or Mana Sources/Deck Analysis) - Expand to fill the 
   full space of .Pane1, delegating scrolling and other behavior to children */
.cardpool-panes-top {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  /* fill the vertical space */
  height: 100%;
  /* for Firefox */
  min-height: 0;   
}

.pool-header, 
.deck-analysis-header {
  display: flex;
  align-items: center;

  background: #000;
  font-size: .9rem;
  padding: 0 3px;
  color: #ccc;

  white-space: nowrap;
}
/* Sideboard */
.cardpool-panes .Pane1 .pool-header {
  /* lower than the drag z-index (5000) but higher 
      than hard-coded drop-to-deck z-index (3000) */
  z-index: 3500;
}
.cardpool-panes .Pane1 .pool-scrollable {
  /* above the drop-to-deck z-index (3000) */
  /* z-index: 4000; */
}
/* Deck */
.cardpool-panes .Pane2 .pool-header {
  /* lower than the drag z-index (5000) but higher 
      than hard-coded drop-to-deck z-index (3000) */
  z-index: 3500;
}
.cardpool-panes .Pane2 .pool-scrollable {
  /* z-index: 3000; */
}

.pool-title, 
.deck-analysis-title {
  font-family: 'Beleren Bold', Georgia, 'Times New Roman', Times, serif;
  font-size: 1.3em;
  padding-top: 0.15em; /* half of the amount that `font-size` is > 1em */
  padding-left: 0.4em;
  padding-right: 0.6em;
  white-space: nowrap;
}

.pool-details,
.pool-controls {
  margin: 0 0.4em;
  font-weight: 500;
  white-space: nowrap;
}

.pool-controls {
  padding-left: 1em;
}

/* Sideboard Controls for Hidden Card View */
.sideboard-controls-hidden {
  margin-left: auto;
  margin-right: 3em;
}

/* Position the 'X Hidden Cards' and 'Hide All' buttons snug/flat against each other */
button.hidden-show {
  margin-left: auto;

  margin-right: 0;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Only apply these styles to the 'Hide All' button (.hidden-hide-all) 
   if it immediately follows the 'X Hidden' button (.hidden-show), 
   and if both are children of the same parent */
button.hidden-show + button.hidden-hide-all {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

button.hidden-hide,
button.hidden-clear {
  margin-left: 1em;
}

.split-switch {
}

/* Position the 'Swap' and 'Clear Deck' buttons snug/flat against each other */
button.deck-swap {
  margin-right: 0;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Only apply these styles to the 'Clear' button (.deck-clear) if it 
   immediately follows the 'Swap' button (.deck-swap), 
   and if both are children of the same parent */
button.deck-swap + button.deck-clear {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media (hover: hover) {
  button.hidden-clear:enabled:hover,
  button.deck-clear:enabled:hover
  button.tierlist-clear:enabled:hover {
    background-color: var(--red-pale);
  }
  button.hidden-clear:active:hover:enabled,
  button.deck-clear:active:hover:enabled,
  button.tierlist-clear:enabled:hover {
    background-color: var(--red-pale-dark);
  }
}

.pool-scrollable,
.deck-analysis-scrollable {
  /* Display flex with direction column for split pool */
  display: flex;
  flex-direction: column;

  /* Force the width of .pool-scrollable to be 0 so the width of the container is 
     defined by the .pool-header (the .pool-scrollable doesn't contribute to the width)
     then force the width again to be 100% using min-width.
     See https://stackoverflow.com/a/55041133/186818 */
  width: 0;
  min-width: 100%;

  height: 100%;
  /* for Firefox */
  min-height: 0;

  overflow-x: scroll;
  overflow-y: auto;

  /* don't allow accidental selecting of items in the pool */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pool-columns {
  /* flex so child columns are aligned next to each other */
  display: inline-flex;
  /* expand to fill space; don't shrink anything; default size to width or height */
  /* flex-grow: 1; flex-shrink: 0; flex-basis: auto */
  flex: 1 0 auto;
  min-width: 100%;
  width: max-content;
  /* at least 100% of space (if space is bigger than columns) */
  min-height: 100%;
  /* but always the size of the max content (if columns extend past visible space) */
  height: max-content;
  background-color: #fff;
}

.pool-filtered,
.pool-disabled {
  background-color: #bbb;
}

.pool-filtered-count {
  text-decoration: line-through;
  color: gray;
}

.pool-columns.error {
  background-color: var(--red-pale);
}

.pool-columns.split-top {
  flex-grow: 0;
  /* confusingly, this keeps it from extending past max-content column */
  min-height: max-content;
}
.pool-columns.split-bottom {
  border-top: solid medium #000;
  min-height: max-content;
}

.column-wrapper {
  display: flex;
  flex-direction: column;
  /* prevent flex-shrink-ing when column has no .card elements */
  flex: 0 0 auto;

  margin: 0 0.35em;
  /* set the column width (needed for empty columns) */
  width: var(--card-width);
}

.column-wrapper.last {
  flex-grow: 1;
  /* NOTE: if the last column is not empty, then this creates an un-droppable area */
  /* prevent the last (presumably empty) column from collapsing entirely */
  min-width: 8em;
  min-height: 1em;
  width: auto;
}

.column-header {
  text-align: center;
  font-size: .8rem;
  padding: 0.4em 0;
  font-weight: bold;
}

.column-title {
  font-size: 1rem;
  padding: 0.2em 0;
}

.column { 
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* padding to expand below the image overflow of the div.card */
  padding-bottom: 16em;
}

.card-draggable {
}

.card {
  width: var(--card-width);
  height: var(--card-height);
  margin: 0;
  background: transparent;
  overflow: visible;
  position: relative;
  /* Hide the focus outline for card elements */
  outline: none;
}

.card.extras { /*has `additional` extras */
  margin-bottom: 1.15em;
}

.card .additional {
  position: relative;
  top: -0.14em;
  padding-left: 0.9em;
}

.card div.missing-image {
  padding: 0 1rem;
}
.card div.missing-image a {
  font-family: 'Beleren Bold', Georgia, 'Times New Roman', Times, serif;
  font-size: 0.9rem;
  text-transform: capitalize;
  color: white;
  outline: none;
}
.card div.missing-image a:hover {
  color: var(--blue-hover);
}

span.rating,
span.gihwr {
  display: inline-block;
  background-color: #333C;
  border-radius: .25em;
  color: #ccc;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  padding: .1em .25em;
  margin: 0 0.1em;
  min-width: 1.5em; /* wide enough for 'D+' */
}

span.gihwr {
  color: white;
}
span.gihwr:hover {
  cursor: help;
}

span.rating i.ss,
span.gihwr i.ss {
  color: white;
  font-size: 1.2em;
  position: relative;
  bottom: 0.1em;
}

/*********************************************************/
/* SetCardPool card styling                              */

.card.origin-proxy img {
  filter: grayscale(65%) brightness(60%);
}

/* Do not grayscale cards being dragged */
.card-draggable.dragging img,
/* Do not grayscale cards being zoomed */
.card .zoom img {
  /* Set specific value instead of `none` to disable */
  /* https://stackoverflow.com/a/45073433/186818 */
  filter: grayscale(0%) brightness(100%);
}

/* End of diff card styling                              */
/*********************************************************/


/*********************************************************/
/* Styling for mana sources/deck analysis view           */

.deck-analysis-body {
  margin: 0 1em;
}

.mana-sources-title,
.deck-stats-title {
  font-weight: bold;
  font-family: 'Beleren Bold', Georgia, 'Times New Roman', Times, serif;
  font-size: 1.3em;
  margin: 0.5em 0 0.3em 0;
}

/* Styling for Mana Sources */

.react-numeric-input input {
  width: 7.5em;
}

.mana-sources-content {
  margin: 0.5em 0;
}

.mana-source {
  display: inline-block;
  text-align: center;
  border-radius: 0.5em;
  margin: 0 0.5em;
  padding: 0.5em;
}
.mana-source .ms-cost {
  border: 2px solid;
}
.mana-source-w {
  background-color: var(--mtg-white);
}
.mana-source-w .ms-cost {
  border-color: var(--mtg-white-dark);
}
.mana-source-u {
  background-color: var(--mtg-blue);
}
.mana-source-u .ms-cost {
  border-color: var(--mtg-blue-dark);
}
.mana-source-b {
  background-color: var(--mtg-black);
}
.mana-source-b .ms-cost {
  border-color: var(--mtg-black-dark);
}
.mana-source-r {
  background-color: var(--mtg-red);
}
.mana-source-r .ms-cost {
  border-color: var(--mtg-red-dark);
}
.mana-source-g {
  background-color: var(--mtg-green);
}
.mana-source-g .ms-cost {
  border-color: var(--mtg-green-dark);
}
.mana-source-c {
  background-color: var(--mtg-colorless);
  min-width: 8.5em;
}
.mana-source-c .ms-cost {
  border-color: var(--mtg-colorless-dark);
}

.mana-source hr {
  background-color: gray;
}

.add-basics {
  margin-top: 0.5rem;
}

.add-basic-land-widget {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5em 0.2em;
}

.add-basic-land-widget img {
  width: 2rem;
  margin-bottom: .3em;
}

.add-basic-land-widget i {
  font-size: 1.3em;
  margin: .2em;
}

/* Styling for Deck Statistics Charts */

.deck-stats {
  margin: 2em 0;
}

.deck-stats-content {
  display: flex;
  padding-left: 0.5em;
}

.deck-stats-chart-option {
  padding: 0.2em 0;
}

.deck-stats-chart-filter-creatures {
  margin: 1.4em 0 0.4em;
}

.custom-tooltip {
  background-color: white;
  border: #999 solid 1px;
  padding: 0.2em;
}

span.tooltip-item {
  display: inline-block;  
  text-align: center;
  margin: 0 0.4em;
}

.tooltip-item .ms {
  /* font-size: 0.9em; */
}

.tooltip-item .tooltip-count {
  font-size: 1.1em;
}

/* Adjust the position of the Black Mana Font symbol _only_ within Recharts */
.recharts-wrapper .ms-b::before {
  position: relative;
  left: 0.01em;
}

/* Adjust the position of the Colorless Mana Font symbol _only_ within Recharts */
.recharts-wrapper .ms-c::before {
  position: relative;
  left: 0.01em;
}

/* Adjust the position of Mana Font symbols within Recharts axis labels */
.recharts-cartesian-axis .ms::before {
  position: relative;
  top: -0.02em;
}

/* Adjust the font size of CMC values (not colors!) within Recharts axis labels */
.recharts-cartesian-axis .ms-cost:not(.ms-w, .ms-u, .ms-b, .ms-r, .ms-g, .ms-c)::before {
  font-size: 0.9em;
  position: relative;
  top: -0.1em;
}

/* Prevent Recharts YAxis label from being clipped */
/* https://github.com/recharts/recharts/issues/1480#issuecomment-549325190 */
.recharts-surface { 
  overflow: visible;
}

/* End of styling for mana sources/deck analysis view    */
/*********************************************************/


/*********************************************************/
/* Styling for double-faced (modal/transform) cards      */

/* Hide the DFC transform button when dragging */
.card-draggable.dragging .transform {
  display: none;
}
/* Hide the zoomed card when dragging */
.card-draggable.dragging .zoom {
  display: none;
}

.card div.transform {
  position: absolute;
  top: -0.3em;
  left: 8.6em;
}
.card button.transform {
  opacity: 0.7;
  margin: 0;
  /* stolen from Scryfall */
  transition: background-color 200ms linear, opacity 50ms linear;
  padding: 0;
  height: 2em;
  width: 2em;
  border-radius: 100%;
  border: 1px solid #343242;
}
/* Only apply hover style to the button if the browser supports 
   proper/true/real/non-emulated hovering (i.e., not touch)
   https://stackoverflow.com/a/28058919/186818 */
@media (hover: hover) {
  .card button:hover.transform {
    opacity: 1;
    background-color: white;
  }
}
.card.transformed button.transform {
  border-color: white;
  background-color: #343242;
  color: white;
}

.card-faces {
  display: inline;
}

.card-faces,
.card-front,
.card-back {
  width: inherit;
  height: inherit;
  font-size: 1rem;
}

.card-back {
  position: absolute;
  top: 0;
  left: 0;
}

/**
 * Flip transitions were giving trouble with .card-faces `display: inline;` 
 * so reverted to just hide/show on flip.
 */
.card-back {
  visibility: hidden;
}
.card.transformed .card-front {
  visibility: hidden;
}
.card.transformed .card-back {
  visibility: visible;
}

/* END of styling for double-faced cards                 */
/*********************************************************/

.card img {
  width: var(--card-width);
  border-radius: 4%;
  box-shadow: 0 0 0 0.5px #999;
}

/* Rounded corners for Alpha */
.card.alpha img {
  border-radius: 6.4%;
}

.card .zoom {
  z-index: 7000;
  white-space: nowrap;
  pointer-events: none;
}

.card .zoom .zoom-front {
  position: relative;
  z-index: 7000;
}
/*********************************************************/
/* START of Resizer styling for react-split-pane library */
/* https://github.com/tomkp/react-split-pane             */

.Resizer {
  background: #333;
  /* Above card deck drop animation z-index (3000), but below dragging */
  z-index: 3500;
}

.Resizer.horizontal {
  height: 6px;
  cursor: row-resize;
  width: 100%;

  /* top/bottom edges */
  border: solid #ccc; 
  border-width: thin 0;
}

.Resizer.vertical {
  width: 6px;
  min-width: 6px;
  cursor: col-resize;

  /* left/right edges */
  border: solid #ccc; 
  border-width: 0 thin;
}

.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
}

/* END of Resizer styling for react-split-pane library   */
/*********************************************************/
