input.label-radio[type='radio'] {
  position: absolute;
  left: 0;

  -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Chrome, Safari, Opera */
  transform: scale(1.3);

  left: 0.2em;
}

/**
 * Style the label as gray when the radio button is disabled
 * 
 * Note: the label *must* follow the input control
 *       https://stackoverflow.com/a/16533513/186818
 */
input.label-radio:disabled[type=radio] + label,
label.label-radio.disabled {
  color: #666;
}

/**
 * Style the label to align a long label to the right of the input element.
 * https://stackoverflow.com/a/56728679/186818
 */
label.label-radio {
  position: relative;
  padding-left: 2em;
  display: block;
}
